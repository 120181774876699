import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { PipesModule} from '@app/shared/pipes/module';
import { MainModule} from '@features/main/main.module';
import { DetailsComponent } from '@features/details/details.component';

@NgModule({
  declarations: [DetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    AgmCoreModule,
    PipesModule,
    MainModule
  ],
  exports: [DetailsComponent]
})
export class DetailsModule { }
