import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { SearchParams } from '@core/interfaces/search-params';
import { Product } from '@core/interfaces/product';
import { CountResponse } from '@core/interfaces/count-response';
import { ProductsResponse } from '@core/interfaces/products-response';

// included to make it a little easier to build and properly encode URI params
import { parse, stringify } from '@billjs/query-string';
import { geocodeResults } from '@testing/__mockData__';

function toRad(v) {
  return v * Math.PI / 180;
}

function haversineDistance(
  lat1,
  lon1,
  lat2,
  lon2
) {
  // See https://stackoverflow.com/questions/14560999/using-the-haversine-formula-in-javascript
  const R = 6371; // km 
  //has a problem with the .toRad() method below.
  const x1 = lat2 - lat1;
  const dLat = toRad(x1);  
  const x2 = lon2 - lon1;
  const dLon = toRad(x2);  
  const a = Math.sin(dLat/2) * Math.sin(dLat/2) + 
            Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
            Math.sin(dLon/2) * Math.sin(dLon/2);  
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  const d = R * c; 
 
  return d; 
}

@Injectable({ providedIn: 'root' })
export class ProductService {
  url = '/solr/';
  apiUrl = '/select?omitHeader=true&indent=on&q=';
  returnFormat = 'wt=json';
  solrUrl = environment.solrUrl;
  productsCollection = environment.productsCollection;
  loading$ = new BehaviorSubject<boolean>(false);
  private data$ = new BehaviorSubject<Product[]>([]);
  private totalCount$ = new BehaviorSubject<number>(0);
  private error$ = new BehaviorSubject<boolean>(false);

  constructor(private httpService: HttpClient) {
  }

  get data(): Observable<Product[]> {
    return this.data$.asObservable();
  }

  get totalCount(): Observable<number> {
    return this.totalCount$.asObservable();
  }

  searchProducts(searchParams: SearchParams) {
    this.loading$.next(true);
    /*const query = this.buildProductGeoQuery(searchParams),
      solrRequest = `${this.solrUrl}${this.url}${
        this.productsCollection}${query}&start=${
        (searchParams.pageIndex - 1) * searchParams.pageSize}&rows=${searchParams.pageSize}`;*/

    const query = this.buildProductGeoQuery(searchParams),
      solrRequest = `${this.solrUrl}${this.url}${
        this.productsCollection}${query}&start=0&rows=1000`;

    this.httpService.get<ProductsResponse>(solrRequest)
      .pipe(
        map(result => {
          // set empty doc list as default if problem with query response
          let docs = [];
          let numFound = 0;

          // these should be passed around for debugging and managing state
          const queryTime = result.responseHeader.QTime;
          const queryStatus = result.responseHeader.status;
          const queryParams = result.responseHeader.params;

          // return docs list for simplest case of ungrouped response
          if (result.response) {
            // get reference to resonse documents for this case
            //docs = result.response.docs;
            console.log("DOCS_LIST");

            // join store subdocument to top level document
            docs = result.response.docs;

            //       'stores.pt': `${searchParams.lat},${searchParams.lng}`
            // docs[0].stores.docs[0].latlon
            docs.forEach(
              (document) => {
                const stores = document.stores.docs;
                if (stores.length > 0) {
                  const distance = haversineDistance(
                    searchParams.lat,
                    searchParams.lng,
                    document.stores.docs[0].latitude,
                    document.stores.docs[0].longitude
                  );

                  document.distance = distance;
                } else {
                  document.distance = 1000000;
                }
              }
            );
            
            // high scores are good
            // low distances are good
            docs.sort(
              (a, b) => 
                b.score / b.distance - 
                a.score / a.distance
            );

            // take from page X to page X + page size
            docs = docs.slice(
              (searchParams.pageIndex - 1) * searchParams.pageSize
            ).slice(0, searchParams.pageSize);

            docs.forEach(function (doc) {
              console.log("FOR_EACH_DOC");
              if (doc.hasOwnProperty('stores')) {
                let stores = doc.stores.docs;
                // console.log("FOR_EACH_STORE: ");
                // console.log(doc.stores);
                // let stores = doc.getAttribute("stores");
                // console.log("STORES: " + stores);
                if (stores.length > 0) {
                  console.log("THERE_WAS_STORE");
                  let store = stores[0];
                  doc.latitude = store.latitude;
                  doc.longitude = store.longitude;
                  doc.address = store.address;
                  doc.phone = store.phone;
                  doc.dist = store.dist;
                  console.log('ADDRESS: ' + doc.address);
                }
              }
            });
            // docs = result.response.docs.map(function (doc) {
            //   if (doc.hasOwnProperty('stores')) {
            //     let stores = doc.stores;
            //     if (stores.length > 0) {
            //       let store = stores[0];
            //       doc.latitude = store.getAttribute('latitude')
            //       doc.longitude = store.getAttribute('longitude')
            //       doc.address = store.getAttribute('address')
            //       console.log('ADDRESS: ' + doc.address);
            //     }
            //   }
            //   return doc;
            // });
            // get number of documents for pagination
            numFound = result.response.numFound;
          }

          // if results are grouped, the first group (assumes only one grouping defined)
          if (result.grouped) {
            console.log("GROUPED_RESPONSE")
            // get the collapse group docs
            const group_key = Object.keys(result.grouped)[0];
            const group = result.grouped[group_key];
            docs = group.doclist.docs;

            // for grouped pagination, need to use ngroups instead of total matches found
            numFound = group.ngroups;
          }

          // return number of matches for pagination and documents found (if any)
          return { numFound: numFound, docs: docs };

        })

      )
      .subscribe(response => {
        this.data$.next(response.docs);
        this.loading$.next(false);
        this.totalCount$.next(response.numFound);
      }, () => {
        this.loading$.next(false);
        return this.error$.next(true);
      });

  }

  buildProductGeoQuery(searchParams: SearchParams) {
    // log search params for debugging purposes
    console.log('SEARCH_PARAMS: ');
    console.log(searchParams);

    var qs = {
      q: 'coffee',
      'q.alt': '*:*',
      qf: 'itemname^5 company^3 itemdescription^1 storename^1 company_key^1',
      fq: 'doctype:product AND {!collapse field=company_key}',
      defType: 'edismax',
      fl: '*,score,stores:[subquery fromIndex=products]',
      'hl.fl': 'itemname company itemdescription',
      hl: 'on',
      stopwords: 'true',
      'stores.q': '{!terms f=company_key v=$row.company_key}',
      'stores.index': 'products',
      'stores.fl': '*,score,dist:geodist()',
      'stores.fq': ['{!geofilt sfield=latlon pt=$pt d=5000}', 'doctype:store'],
      'stores.pt': '42.174689,-72.5798757',
      'stores.sfield': 'latlon',
      'stores.sort': 'geodist() asc',
      'stores.rows': '1'
    };

    // parameters that govern how overall query request works
    const queryBase = {
      q: '',
      'q.alt': '*:*',
      omitHeader: false,
      wt: 'json',
      'json.nl': 'flat',
      sort: 'score desc',
      //fq: '{!geofilt}',
      fq: '{!collapse field=company_key}',
      fl: '*,score,stores:[subquery fromIndex=products]',
      // set query type to dismax and set empty query to match everything
      defType: 'edismax',
      qf: 'itemname^5 company^3 itemdescription^1 storename^1 company_key^2 category^2',
      'hl.fl': 'itemname company itemdescription',
      hl: 'on',
      stopwords: 'true',
      'stores.q': '{!terms f=company_key v=$row.company_key}',
      'stores.index': 'products',
      'stores.fl': '*,score,dist:geodist()',
      'stores.fq': ['{!geofilt sfield=latlon pt=$pt d=5000}', 'doctype:store'],
      //'stores.pt': '42.174689,-72.5798757',
      'stores.sfield': 'latlon',
      'stores.sort': 'geodist() asc',
      'stores.rows': '1'
    };
    const queryBaseOld = {
      q: '',
      omitHeader: false,
      wt: 'json',
      'json.nl': 'flat',
      sort: 'geodist() asc,score desc',
      fq: '{!geofilt}',
      fl: '*,score,dist:geodist()',
      // set query type to dismax and set empty query to match everything
      defType: 'edismax',
      qf: 'company^1000 itemname^5000 itemdescription^10 category^100', // field weighting
      'q.alt': '*:*'
    };

    // parameters that govern how collapse by company works
    const queryCollapse = {
      expand: searchParams.group !== 'true' || 'false'
      // 'group.field': searchParams.groupBy,
      // 'group.main': false,
      // 'group.format': 'simple',
      // 'group.sort': 'geodist() asc,score desc',
      // 'group.ngroups': true
    };

    // parameters that govern location based search
    const queryLocation = {
      'stores.sfield': 'latlon',
      'stores.d': searchParams.dist,
      'stores.pt': `${searchParams.lat},${searchParams.lng}`
    };
    const queryLocationOld = {
      sfield: 'latlon',
      d: searchParams.dist,
      pt: `${searchParams.lat},${searchParams.lng}`
    };

    // convert query parameter into a format that matches individual keywords
    // but boosts exact phrase matches (TODO: look into lucene shingle support)
    const query_text = searchParams.products.split(' ').length > 1 ?
      `${searchParams.products} "${searchParams.products}"^100` : searchParams.products;

    // make a version for view items that will show everything but still boost
    // by relevancy scoring
    const fuzzy_query_text = searchParams.products.split(' ').length > 1 ?
      `(${searchParams.products} "${searchParams.products}") OR *` : searchParams.products;

    console.log('QUERY_TEXT: ' + query_text);

    // parameters that govern query behavior relative to query terms
    // TODO: refactor logic to be more readable
    //const queryCore = { q: query_text, fq: queryBase.fq || '', group: queryCollapse.group || 'false' };
    const queryCore = { q: query_text, fq: queryBase.fq || '', expand: queryCollapse.expand || 'false' };

    // if business filter is used, update filters
    if (searchParams.biz) {
      queryCore.fq = `company_key:${searchParams.biz}` + (queryCore.fq && ` AND ${queryCore.fq}`);
      // change collapse to storeid since its constrained to a business and we want
      // list all individual stores
      queryCore['group.field'] = 'storeid';
      queryCore.q = fuzzy_query_text;
    }

    // if business filter is used, update filters
    if (searchParams.store) {
      queryCore.fq = `storeid:${searchParams.store}` + (queryCore.fq && ` AND ${queryCore.fq}`);
      // turn grouping off entirely if business is specified too
      queryCore.expand = 'false';
      queryCore.q = fuzzy_query_text;
    }

    // if category filter is used, update filters ()
    if (searchParams.cat && searchParams.cat !== 'all') {
      queryCore.fq = `category:${searchParams.cat}` + (queryCore.fq && ` AND ${queryCore.fq}`);
    }

    // merge all parts of the query together overriding queryBase values as needed
    const finalQuery = { ...queryBase, ...queryCollapse, ...queryLocation, ...queryCore };

    console.log('QUERY_PARAMS: ');
    console.log(finalQuery);

    return '/select?' + stringify(finalQuery);
  }

}
