import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';

import { CoreModule } from '@core/core.module';
import { FeaturesModule } from '@features/features.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { GoogledoDirective } from '@app/googledo.directive';
import {environment} from '../environments/environment';
import {SharedModule} from '@app/shared/module';
import {ContactComponent} from '@app/components/contact/contact.component';
import {PrivacyComponent} from '@app/components/privacy/privacy.component';
import {TermsComponent} from '@app/components/terms/terms.component';

@NgModule({
  declarations: [
    AppComponent,
    GoogledoDirective,
    ContactComponent,
    PrivacyComponent,
    TermsComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AgmCoreModule.forRoot({ apiKey: environment.gMapKey }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    FeaturesModule,
    SharedModule
  ],
  providers: [GoogleMapsAPIWrapper],
  bootstrap: [AppComponent]
})
export class AppModule { }
