import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Category } from '@core/interfaces/category';



@Injectable({ providedIn: 'root' })
export class CategoryService {
  private categories$ = new BehaviorSubject<Category[]>([]);
  private error$ = new BehaviorSubject<boolean>(false);

  get categories(): Observable<any[]> {
    return this.categories$.asObservable();
  }
  constructor(private httpService: HttpClient) {
  }

  load() {
    this.httpService.get<Category[]>('../assets/category.json')
      .subscribe((data) => {
        this.categories$.next(data);
      });
  }
}
