import {Injectable} from '@angular/core';
import {BehaviorSubject, of, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, switchMap} from 'rxjs/operators';
import {OriginLocation} from '@core/interfaces/origin-location';
import {environment} from '@env/environment';

/**
 * GeocodingService class.
 * https://developers.google.com/maps/documentation/javascript/
 */
@Injectable({providedIn: 'root'})
export class MapService {

  geocoder: google.maps.Geocoder;

  private originLocation$ = new BehaviorSubject<OriginLocation>(null);
  private error$ = new BehaviorSubject<boolean>(false);

  constructor(private httpService: HttpClient) {
  }

  get originLocation() {
    return this.originLocation$.asObservable();
  }

  /**
   * Reverse geocoding by location.
   *
   * Wraps the Google Maps API geocoding service into an observable.
   *
   * @param latLng Location
   * @return An observable of GeocoderResult
   */
  geocode(lat, lng) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${environment.gMapKey}`;

    this.httpService.get<{ status: google.maps.GeocoderStatus, results: any[] }>(url)
      .pipe(
        switchMap(geocode =>
            geocode.status === google.maps.GeocoderStatus.OK ? of(geocode.results) : throwError(geocode)
        ),
        map(results => results[0]),
        map((result): OriginLocation => {
          const comp = result.address_components
            .find(item => !!item.types.find(type => type === 'administrative_area_level_1')) || {};
          return {
            lat: result.geometry.location.lat,
            lng: result.geometry.location.lng,
            address: {
              formatted: result.formatted_address,
              long: comp.long_name,
              short: comp.short_name
            }
          };
        })
      )
      .subscribe(location => {
        this.setOriginLocation(location);
      }, err => {
        this.error$.next(true);
        console.log(
          'Geocoding service: geocode was not successful for the following reason: '
          + err.status
        );
      });
  }

  /**
   * Geocoding service.
   *
   * Wraps the Google Maps API geocoding service into an observable.
   *
   * @param address The address to be searched
   * @return An observable of GeocoderResult
   */
  codeAddress(address: string) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address.split(' ').join('+')}&key=${environment.gMapKey}`;

    this.httpService.get<{ status: google.maps.GeocoderStatus, results: any[] }>(url)
      .pipe(
        switchMap(geocode =>
          geocode.status === google.maps.GeocoderStatus.OK ? of(geocode.results) : throwError(geocode)
        ),
        map(results => results[0]),
        map((result): OriginLocation => {
          const comp = result.address_components
            .find(item => !!item.types.find(type => type === 'administrative_area_level_1')) || {};
          return {
            lat: result.geometry.location.lat,
            lng: result.geometry.location.lng,
            address: {
              formatted: result.formatted_address,
              long: comp.long_name,
              short: comp.short_name
            }
          };
        })
      )
      .subscribe((location) => {
        this.setOriginLocation(location);
      }, err => {
        this.error$.next(true);
        console.log(
          'Geocoding service: geocode was not successful for the following reason: '
          + err.status
        );
      });
  }

  setOriginLocation(location: OriginLocation) {
    this.originLocation$.next(location);
  }

  useDefault() {
    console.log('SET DEFAULT LOCATION');
    this.geocode(environment.defaultLocation.lat, environment.defaultLocation.lng);
  }
}
