import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss']
})
export class BusinessCardComponent implements OnChanges {
  @Input() item: any;
  @Output() navigate = new EventEmitter<{ item: any, type: string }>();
  lat: number;
  lon: number;

  cautionTape = true;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.item.currentValue) {
      this.lat = parseFloat(this.item.latitude);
      this.lon = parseFloat(this.item.longitude);
    }
  }

  navigateClicked(item, type) {
    this.navigate.emit({ item: item, type: type });
  }

  mapClicked(map) {
    console.log('map', map);
  }
}
