import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bizImg'
})
export class BizImgPipe implements PipeTransform {
  transform(value: any): any {
    // TODO: suggest company logo has a logo fetching endpoint that fetches image and caches
    //       company images to a local directory or have devops prebuild business logo cache
    //       since some businesses may notice image cross-linking and ip block biisby servers.
    //       Maybe set cache expiration time to be N days since the cache was updated like
    //       a couple weeks since logo images shouldn't change that frequently
    //       -mmcintosh@esperdyne

    // legacy way (actually proper way, should be cached and not pull from original sites)
    // return '../../assets/biz/' + value.biz.replace(/[^\a-zA-Z0-9]/gi, '').toLowerCase() + '.jpg';

    // fetch company logo url included with item/product document
    return value.companylogo;
  }

}
