import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';


interface ContactInfo {
  userData: {
    name: string;
    email: string;
  };
  message: string;
}

@Injectable({ providedIn: 'root' })
export class ContactService {
  private sent$ = new BehaviorSubject<boolean>(false);
  private error$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  send(contactInfo: ContactInfo) {
    this.http.post('/api/v1/emails/contact', contactInfo)
      .subscribe(
        _ => this.sent$.next(true),
        _ => this.error$.next(true)
      );
  }
}
