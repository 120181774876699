import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-business-list',
  templateUrl: './business-list.component.html',
  styleUrls: ['./business-list.component.scss']
})
export class BusinessListComponent  {
  @Input() data: any[];
  @Output() navigate = new EventEmitter<{ item: any, type: string }>();

  navigateClicked(data) {
    this.navigate.emit(data);
  }

  mapClicked(data) {
    console.log(data);
  }
}
