import {NgModule} from '@angular/core';
import {SizesPipe} from '@app/shared/pipes/sizes.pipe';
import {BizImgPipe} from '@app/shared/pipes/biz-img.pipe';
import {CalcPricePipe} from '@app/shared/pipes/calc-price.pipe';
import {EllipsisPipe} from '@app/shared/pipes/ellipsis.pipe';

@NgModule({
  declarations: [
    BizImgPipe,
    CalcPricePipe,
    EllipsisPipe,
    SizesPipe
  ],
  exports: [
    BizImgPipe,
    CalcPricePipe,
    EllipsisPipe,
    SizesPipe
  ]
})
export class PipesModule {}
