import {Component, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-privacy',
  template: `
    <div class="container">
      <h1>Privacy Policy</h1>
      <p>When using this service, information you provide may be collected or used for various purposes.</p>
      <p>We may use cookies, including third party cookies.</p>
      <p>We may detect your location.</p>
      <p>We may collect data.</p>
      <p>Data may be used to serve ads.</p>
      <p>We may share data.</p>
    </div>
  `
})
export class PrivacyComponent implements OnDestroy {
  private sub: Subscription;
  data = new Subject<string[]>();

  constructor(private http: HttpClient) {
    this.load();
  }

  ngOnDestroy(): void { this.sub.unsubscribe(); }

  load() {
    this.sub = this.http.get<string[]>('../assets/privacy.json')
      .subscribe((data) => this.data.next(data) );
  }
}
