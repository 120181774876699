import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sizes'
})
export class SizesPipe implements PipeTransform {
  transform(value: any): any {
    return value ? '[' + value + ']' : '';
  }
}
