import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { MainComponent } from '@features/main/containers/main/main.component';
import { BusinessListComponent } from '@features/main/components/business-list/business-list.component';
import { BusinessCardComponent } from '@features/main/components/business-card/business-card.component';
import { ProductComponent } from '@features/main/components/product/product.component';
import { SearchFormComponent } from '@features/main/components/search-form/search-form.component';
import { PipesModule } from '@app/shared/pipes/module';
import {UiSwitchModule} from 'ngx-toggle-switch';

@NgModule({
  declarations: [
    MainComponent,
    BusinessListComponent,
    BusinessCardComponent,
    ProductComponent,
    SearchFormComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        AgmCoreModule,
        NgbModule,
        PipesModule,
        UiSwitchModule
    ],
  exports: [MainComponent],
  providers: [CurrencyPipe]
})
export class MainModule { }
