import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DetailsModule } from './details/details.module';
import { MainModule } from './main/main.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MainModule,
    DetailsModule
  ]
})
export class FeaturesModule { }
