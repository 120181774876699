import { Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {ContactService} from '@core/services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent {

  form: FormGroup;

  constructor(
    private http: HttpClient,
    private contactService: ContactService
  ) {
    this.form = new FormGroup({
      'userData': new FormGroup({
        'email': new FormControl(null, [Validators.required, Validators.email]),
        'name': new FormControl(null, [Validators.required])
      }),
      'message': new FormControl(null, [Validators.required])
    });
  }

  onSubmit() {
    this.contactService.send(this.form.value);
  }
}
