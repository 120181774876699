import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {TermsComponent} from '@app/components/terms/terms.component';
import {ContactComponent} from '@app/components/contact/contact.component';
import {PrivacyComponent} from '@app/components/privacy/privacy.component';
import {MainComponent} from '@features/main/containers/main/main.component';
import {DetailsComponent} from '@features/details/details.component';
import {CommonModule} from '@angular/common';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  {
    path: 'terms',
    component: TermsComponent
  },

  {
    path: 'contact',
    component: ContactComponent
  },

  {
    path: 'privacy',
    component: PrivacyComponent
  },

  {
    path: 'home',
    children: [
      {
        path: '',
        component: MainComponent
      },
      {
        path: 'view/:id',
        component: DetailsComponent
      },
      {
        path: ':opt',
        component: MainComponent
      },
      {
        path: ':item/:biz/:cat/:lat/:lng/:d/:group/:group_by',
        component: MainComponent
      },
      { path: '**', redirectTo: 'home', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  declarations: [
    /*TermsComponent,
    ContactComponent,
    PrivacyComponent*/
  ],
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
