import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Business } from '@core/interfaces/business';
import { SearchParams } from '@core/interfaces/search-params';
import { Category } from '@core/interfaces/category';
import { OriginLocation } from '@core/interfaces/origin-location';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit, OnChanges {
  @Input() searchParams: SearchParams;
  @Input() originLocation: OriginLocation;
  @Input() businesses: Business[];
  @Input() categories: Category[];
  @Input() lState: string;
  @Input() sState: string;
  @Output() search = new EventEmitter<SearchParams>();
  @Output() addressChange = new EventEmitter<string>();

  cautionTape = true;

  address: string;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.originLocation && changes.originLocation.currentValue) {
      this.address = changes.originLocation.currentValue.address.formatted;
    }
  }

  searchClicked() {
    this.search.emit(this.searchParams);
  }

  addressChanged() {
    this.addressChange.emit(this.address);
  }

  updateSearchCategory(id) {
    this.searchParams = {
      ...this.searchParams,
      cat: id
    };
    this.search.emit(this.searchParams);
  }
}
