import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';

import { CategoryService } from '@core/services/category.service';
import { ProductService } from '@core/services/product.service';
import { MapService } from '@core/services/map.service';
import { Category } from '@core/interfaces/category';
import { SearchParams } from '@core/interfaces/search-params';
import { OriginLocation } from '@core/interfaces/origin-location';


const noop = null;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  searchParams: SearchParams;

  address: string;
  lState: string;
  sState: string;

  data$: Observable<any[]>;
  categories$: Observable<Category[]>;
  totalCount$: Observable<number>;
  originLocation$: Observable<OriginLocation>;
  loading$: Observable<boolean>;

  positionWatcherId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private categoryService: CategoryService,
    private geocoding: MapService,
    private productService: ProductService
  ) {
  }

  ngOnInit() {
    this.searchParams = {
      products: 'Coffee',
      lat: 0,
      lng: 0,
      cat: 'all',
      dist: 5000,
      group: 'true',
      groupBy: 'company_key',
      pageSize: 10,
      pageIndex: 1
    };

    this.data$ = this.productService.data.pipe(
      // RESET SCROLL
      tap(_ => {
        window.scroll(0, 0);
        console.log(_);
      })
    );

    this.originLocation$ = this.geocoding.originLocation
      .pipe(
        filter(location => !!location),
        tap(location => {
          this.searchParams = {
            ...this.searchParams,
            lat: location.lat,
            lng: location.lng
          };
          this.lState = location.address.long;
          this.sState = location.address.short;
          this.updateNavigation();
        })
      );

    this.totalCount$ = this.productService.totalCount;
    this.categories$ = this.categoryService.categories;

    this.categoryService.load();
    this.initiateSearch();
  }

  initiateSearch() {
    combineLatest([this.route.paramMap, this.route.queryParamMap.pipe(startWith(null))])
      .pipe(
        tap(([params]) => !params.has('opt') ? this.navigateSearch() : noop),
        filter(([opt, query]) => !!opt && (!!query && query.has('products'))),
        map(([params, query]): [string, ParamMap] => [params.get('opt'), query]),
        map(([opt, query]) => ({
          products: query.get('products'),
          biz: query.get('biz'),
          store: query.get('store'),
          cat: query.get('cat'),
          lat: parseFloat(query.get('lat')),
          lng: parseFloat(query.get('lng')),
          pageSize: parseInt(query.get('pageSize'), 10),
          pageIndex: parseInt(query.get('pageIndex'), 10),
          group: (opt === 'biz') ? 'false' : 'true',
          groupBy: (opt === 'loc') ? 'address' : 'company_key',
          dist: (opt === 'biz') ? 1 : (opt === 'loc') ? 500 : 5000
        })),
        filter(search => !!search.lat && !!search.lng),
        tap(search => this.searchParams = search),
      )
      .subscribe(() => this.showResult());
  }

  // google maps zoom level
  addressChanged(address: string) {
    this.address = address;
    this.searchParams.pageIndex = 1;
    this.navigateSearch();

    this.geocoding.codeAddress(address);
  }

  useCurrentLocationChanged(enabled: boolean) {
    if (enabled) {
      if (navigator.geolocation) {
        this.positionWatcherId = navigator.geolocation.watchPosition((position) => {
          this.geocoding.geocode(position.coords.latitude, position.coords.longitude);
        }, err => this.geocoding.useDefault(), {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 5000
        });
      } else {
        this.geocoding.useDefault();
        console.log('Geolocation is not supported by this browser.');
      }
    } else {
        navigator.geolocation.clearWatch(this.positionWatcherId);
        this.addressChanged('');
    }
  }

  loadProducts() {
    this.productService.searchProducts(this.searchParams);
  }

  navigateClicked(data: { item, type }) {
    switch (data.type) {
      case 'biz':
        this.navigateByBiz(data.item);
        break;

      case 'location':
        this.navigateByLocation(data.item);
        break;

      case 'details':
        this.navigateDetails(data.item);
        break;
    }
  }

  navigateSearch() {
    if (this.searchParams.lat && this.searchParams.lng && this.searchParams.products) {
      const navigationExtras: NavigationExtras = {
        queryParams: { ...this.searchParams }
      };
      this.router.navigate(['home/search'], navigationExtras);
    } else {
      this.showAlert('Please provide a valid address and search term', 'info');
      this.router.navigate(['home/search']);
    }
  }

  navigateByBiz(item) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        ...this.searchParams,
        biz: item.company_key,
        store: item.storeid
      }
    };

    this.router.navigate(['/home/biz'], navigationExtras);
  }

  navigateByLocation(item) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        ...this.searchParams,
        biz: item.company_key
      }
    };

    this.router.navigate(['/home/loc'], navigationExtras);
  }

  navigateDetails(item) {
    const url = `/home/view/${item.id}`;
    const params = JSON.stringify(item);

    this.router.navigate([url], { queryParams: { prod: params } });
    this.initiateSearch();
  }

  search(searchParams: SearchParams) {
    this.searchParams = { ...searchParams, pageIndex: 1 };
    this.navigateSearch();
  }

  showAlert(message: string, type: string) {
    console.log('BiisBy', message, type);
  }

  showResult() {
    this.loadProducts();
  }

  updateNavigation(): void {
    const navigationExtras: NavigationExtras = { queryParams: this.searchParams };
    this.router.navigate([], navigationExtras);
  }

  onPageChanged(page: number) {
    if (page) {
      this.updateNavigation();
    }
  }
}
