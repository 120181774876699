import {Component, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html'
})
export class TermsComponent implements OnDestroy {
  private sub: Subscription;
  data = new Subject<string>();

  constructor(private http: HttpClient) {
    this.load();
  }

  ngOnDestroy(): void { this.sub.unsubscribe(); }

  load() {
    this.sub = this.http.get<string>('../assets/terms.json')
      .subscribe((data) => this.data.next(data) );
  }
}
