import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { } from 'googlemaps';
// const google = require('@types/googlemaps');

@Directive({
  selector: '[appGoogledo]'
})
export class GoogledoDirective implements OnInit  {
  private element: HTMLInputElement;
  @Input() lat: any ;
  @Input() lng: any ;
  constructor(private elRef: ElementRef) {
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.element);
    // this.lat = '28.4595';
    // this.lng = '77.0266';
    const mapProp = {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: 14,
      // mapTypeId: google.maps.MapTypeId.ROADMAP
      mapTypeId: google.maps.MapTypeId.HYBRID
      // mapTypeId: google.maps.MapTypeId.SATELLITE
      // mapTypeId: google.maps.MapTypeId.TERRAIN
    };

    const mapz = new google.maps.Map(this.element, mapProp);
    const marker = new google.maps.Marker({ position: mapProp.center });
    marker.setMap(mapz);

    const infowindow = new google.maps.InfoWindow({
      content: 'Hey, We are here'
  });
    infowindow.open(mapz, marker);
  }

}
