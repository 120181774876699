import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'calcPrice'
})
export class CalcPricePipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) { }

  transform(str: any): any {
    // adjust string to cope with Solr currency formatting of <price>,<currency type>
    // such as "9.99,USD" if that format is used
    if (typeof str === 'string' && str.length > 0 && str.includes(',')) {
      str = str.split(',')[0];
    }

    const price = (!str || str === 'undefined' || str === '' || str === 'null' || str === null) ? 'nan' : str;
    return isNaN(price) || price === '0.0' ? 'Price Not Available' : this.currencyPipe.transform(price);
  }
}
