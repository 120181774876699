// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  gMapKey: 'AIzaSyCm5odlv5Dj06znvu17Cne4K7mxExVTehg',
  solrUrl: 'https://search01.hippopotamusapp.com',
  //solrUrl: 'http://localhost:8983',
  //solrUrl: 'http://localhost:8888',
  productsCollection: 'products',
  defaultLocation: {
    lat: 33.421978,
    lng: -111.940061
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
